import {AbstractControl, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {getCurrencySymbol} from '@angular/common';
import {environment} from '../../../environments/environment.prod';
import {PhoneNumber} from '../../models/others/phone-number';
import {PhoneNumberUtil} from "google-libphonenumber";
import {NameCodeIdPojo, NameIdPojo} from "../../../../sdk/customer-fulfillment-api-sdk";
import {AttachmentDto, AttachmentPojo} from "../../../../sdk/customer-fulfillment-api-sdk";
import TypeEnum = AttachmentDto.TypeEnum;
import {FormHelper} from "../../models/etc/form-helper";


const phoneNumberUtil = PhoneNumberUtil.getInstance();

export class Utils {
    static getFileUrl(fileId: number, download = true): string {
        return download
            ? `${environment.apiBaseUrl}/files/${fileId}`
            : `${environment.apiBaseUrl}/files/${fileId}/open`;
    }

    months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    public ctrDown = false;

    get alphabetsOnlyErrorMsg(): unknown[] {
        return [
            {
                error: 'pattern',
                format: (label: any) => `${label} can only contain alphabets.`
            }
        ];
    }

    get digitsOnlyErrorMsg(): unknown[] {
        return [
            {
                error: 'pattern',
                format: (label: any) => `${label} can only contain digits.`
            }
        ];
    }

    get phoneErrorMsg(): unknown[] {
        return [
            {
                error: 'phone',
                format: (label: any) => `${label} number is invalid.`
            }
        ];
    }


    static getRandomColor(): string {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    static getUniqueArray(input: any[]): string {
        const filteredArr = input.reduce((acc, current) => {
            const x = acc.find((item: { name: any; }) => item.name === current.name);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);
        return filteredArr;
    }

    // static maskCharacters(String )

    public static noWhitespaceValidator(control: FormControl): unknown {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : {whitespace: true};
    }

    public static maskCharacter(str: string, mask: string, n = 1): string {
        return ('' + str).slice(0, -n).replace(/./g, mask) + ('' + str).slice(-n);
    }

    public static preventNumberInput(event: KeyboardEvent): void {
        const charCode = typeof event.which == 'undefined' ? event.keyCode : event.which;
        const charStr = String.fromCharCode(charCode);
        // Check if the input is from the calculator section of the keyboard
        if (event.code.includes('Numpad')) {
            event.preventDefault();
        } else if (/\d/.test(charStr)) {
            event.preventDefault();
        }
    }

    public static numberOnly(e: KeyboardEvent): void {
        if (
            // Only allow digits 0-9
            (e.keyCode < 48 || e.keyCode > 57) &&
            // Only allow decimal point (.)
            e.key !== '.' &&
            // Allow backspace (keyCode 8)
            e.keyCode !== 8 &&
            // Allow arrow keys (37-40)
            (e.keyCode < 37 || e.keyCode > 40)
        ) {
            e.preventDefault();
        }
    }

    public static preventAlphabetInput(e: KeyboardEvent): void {
        // const keyCode = e.keyCode;
        if (
            (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
            (e.keyCode < 96 || e.keyCode > 105) &&
            e.keyCode != 8
        ) {
            e.preventDefault();
        }
    }

    public static currencySymbol(iso4127Code: string, defaultSymbol = ""): string {
        let symbol = getCurrencySymbol(iso4127Code, 'narrow');
        if (symbol == iso4127Code) {
            switch (iso4127Code?.toLowerCase()) {
                case 'zwl':
                    symbol = '$';
                    break;
            }
        }
        if (defaultSymbol) {
            symbol = defaultSymbol;
        }
        return symbol;
    }

    public static preventAlphabetInputV2(e: KeyboardEvent): void {
        //TODO implement to handle paste and copy actions
    }

    preventAlphabetInput($event: KeyboardEvent): void {
        const cmdKey = 91;
        const vKey = 86;
        const cKey = 67;
        const aKey = 65;
        const excludedKeys = [8, 37, 39, 46];
        if ($event.ctrlKey || $event.keyCode == cmdKey) {
            this.ctrDown = true;
        }

        if (this.ctrDown && $event.keyCode == vKey) {
            //paste action, handle appropriately
        } else if (this.ctrDown && $event.keyCode == cKey) {
            //copy action, handle appropriately
        } else if (this.ctrDown && $event.keyCode == aKey) {
            //select all action, handle appropriately
        } else if (
            !(
                ($event.keyCode >= 48 && $event.keyCode <= 57) ||
                ($event.keyCode >= 96 && $event.keyCode <= 105) ||
                excludedKeys.includes($event.keyCode)
            )
        ) {
            $event.preventDefault();
        }
        //TODO to be moved to Utils.preventAlphabetInputV2($event)
    }


    static enumEntries<T>(t: T): ReadonlyArray<readonly [keyof T, T[keyof T]]> {
        const entries = Object.entries(t);
        const plainStringEnum = entries.every(([key, value]) => typeof value === 'string');
        return (plainStringEnum ? entries : entries.filter(([k, v]) => typeof v !== 'string')) as any;
    }

    static enumKeys<T>(t: T): ReadonlyArray<keyof T> {
        return this.enumEntries(t).map(([key]) => key);
    }

    static enumValues<T>(t: T): Array<T[keyof T]> {
        const values = Object.values(t);
        const plainStringEnum = values.every((x) => typeof x === 'string');
        return plainStringEnum ? values : values.filter((x) => typeof x !== 'string');
    }

    static convertAttachmentPojoToDto(attachmentPojo: AttachmentPojo) {
        return {
            type: this.mimeTypeToTypeEnum(attachmentPojo?.fileType) as TypeEnum,
            fileName: attachmentPojo?.name ?? "",
            bwBinaryDataId: attachmentPojo?.id
        }
    }

    static entityNameToReadableText(pascalCaseString: string) {
        return pascalCaseString
            .replace(FormHelper.regexLowerToUpper, '$1 $2')
            .replace(FormHelper.regexUpperToLower, '$1 $2')
            .toLowerCase()
            .replace(/^./, str => str.toUpperCase());
    }

    static mimeTypeToTypeEnum(mimeType) {
        const [type, subtype] = mimeType.toLowerCase().split('/');

        switch (type) {
            case 'image':
                return 'image';
            case 'application':
                if (subtype === 'pdf') return 'pdf';
                if (subtype === 'vnd.ms-excel' || subtype === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' || subtype === 'vnd.ms-excel.sheet.macroenabled.12') {
                    return 'excel';
                }
                break;
            case 'audio':
                return 'audio';
            case 'text':
                if (subtype === 'svg+xml') return 'svg';
                break;
        }
        return undefined;

        throw new Error(`Unsupported MIME type: ${mimeType}`);
    }


    static getOrdinalSuffix(i: number): string {
        const j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + 'st';
        }
        if (j == 2 && k != 12) {
            return i + 'nd';
        }
        if (j == 3 && k != 13) {
            return i + 'rd';
        }
        return i + 'th';
    }

    static generateColorFromString(str: string): string {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xFF;
            color += ('00' + value.toString(16)).substr(-2);
        }
        return color;
    }

    static isValidURL(urlString: string): boolean {
        if (!urlString) {
            return false;
        }
        try {
            // If the URL starts with "www.", prepend "http://"
            const formattedUrlString = urlString.toLowerCase().startsWith('www.')
                ? `https://${urlString}`
                : urlString;

            const url = new URL(formattedUrlString);
            return true;
        } catch {
            return false;
        }
    }


    static convertMinutesToHoursAndMinutes(minutes: number): string {
        const hours = Math.floor(minutes / 60);
        const mins = Math.floor(minutes % 60);
        const seconds = Math.round((minutes - Math.floor(minutes)) * 60);

        // Build the output string
        let result = `${hours}h ${mins}m`;
        if (seconds > 0) {
            result += ` ${seconds}s`;
        }
        return result;
    }

    static addTransparency(color: string, opacity: number): string {
        if (color != undefined) {
            const rgba = this.hexToRgba(color, opacity);
            return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
        }

        return color;
    }

    static darkenColor(hex: string, amount: number): string {
        if (hex != undefined) {
            const {r, g, b} = this.hexToRgb(hex);
            const darken = (value: number) => Math.max(0, value - value * amount);
            return `rgb(${darken(r)}, ${darken(g)}, ${darken(b)})`;
        }
        return hex;
    }

    static hexToRgba(hex: string, opacity: number) {
        const bigint = parseInt(hex.replace('#', ''), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return {r, g, b, a: opacity};
    }

    static hexToRgb(hex: string) {
        const bigint = parseInt(hex.replace('#', ''), 16);
        return {
            r: (bigint >> 16) & 255,
            g: (bigint >> 8) & 255,
            b: bigint & 255,
        };
    }


    public static systemIconsFilePath(icon: string): string {

        switch (icon) {
            case 'mingcute:process-line':
                return 'assets/systemicons/mingcute--process-line.png';
            case 'gridicons:add-outline':
                return 'assets/systemicons/gridicons--add-outline.png';
            case 'uim:process':
                return 'assets/systemicons/uim--process.png';
            case 'carbon:document':
                return 'assets/systemicons/carbon--document.png';
            case 'lucide:edit':
                return 'assets/systemicons/lucide--edit.png';
            case 'clarity:process-on-vm-line':
                return 'assets/systemicons/clarity--process-on-vm-line.png';
            case 'pepicons-pencil:eye-circle':
                return 'assets/systemicons/pepicons-pencil--eye-circle.png';
            case 'simple-icons:thunderbird':
                return 'assets/systemicons/simple-icons--thunderbird.png';
            case 'ant-design:thunderbolt-twotone':
                return 'assets/systemicons/ant-design--thunderbolt-twotone.png';
            case 'material-symbols:code':
                return 'assets/systemicons/material-symbols--code.png';
            case 'cuida:monitor-outline':
                return 'assets/systemicons/cuida--monitor-outline.png';
            case 'fluent-mdl2:not-executed':
                return 'assets/systemicons/fluent-mdl2--not-executed.png';
            case 'fluent-mdl2:documentation':
                return 'assets/systemicons/fluent-mdl2--documentation.png';
            case 'fluent-mdl2:release-gate-check':
                return 'assets/systemicons/fluent-mdl2--release-gate-check.png';
            case 'material-symbols:settings':
                return 'assets/systemicons/material-symbols--settings.png';
            case 'ix:reset':
                return 'assets/systemicons/ix--reset.png';
            default:
                return 'assets/systemicons/ix--reset.png';
        }

    }

    static readonly ALPHANUMERIC_WITH_SPACES_AND_DASH_AND_SLASH_MUST_CONTAIN_ALPHABETS: RegExp = /^(?=.*[A-Za-z])[A-Za-z0-9\s/\\-]+$/;
    static readonly ALPHANUMERIC_WITH_SPACES_AND_DASH_AND_SLASH: RegExp = /^[A-Za-z0-9\s/\\-]+$/;
    static readonly TEXT_FIELD_WITHOUT_NUMBER_AND_SPECIALS: RegExp = /^[A-Za-z]+([-'][A-Za-z]+)*([ ][A-Za-z]+([-'][A-Za-z]+)*)*$/;
    static readonly NAME_FIELD_MIN_LENGTH = 2;
    static readonly NAME_FIELD_MAX_LENGTH = 50;
    static readonly CHAR_250 = 2000;
    static readonly NAME_REGEX = "^[A-Za-z0-9][A-Za-z][._ -'A-Za-z0-9s]*$";
    static readonly VERSION_PATTERN = "^(0|[1-9]\\d*)\\.(0|[1-9]\\d*)\\.(0|[1-9]\\d*)(?:-((?:0|[1-9]\\d*|\\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\\.(?:0|[1-9]\\d*|\\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\\+([0-9a-zA-Z-]+(?:\\.[0-9a-zA-Z-]+)*))?$";
}

/**
 *
 * @param obj
 */
export function removeUndefinedOrNullFields(obj: any): any {
    Object.keys(obj).forEach((key) => {
        if (
            obj[key] === undefined ||
            obj[key] === 'null' ||
            obj[key] === null ||
            obj[key] === '' ||
            obj[key] < 1
        ) {
            delete obj[key];
        }
    });

    return obj;
}

export function telePhoneNumberValidator(config: Config = {}): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const value: PhoneNumber = control.value;

        const error = {};
        error[config.errorCode || 'invalidPhoneNumber'] = true;

        if (!value) {
            return null;
        }
        if (!value.internationalNumber) {
            return error;
        }
        try {
            const phoneNumber = phoneNumberUtil.parse(value.internationalNumber.replace(/ /g, ''));
            if (phoneNumberUtil.isValidNumber(phoneNumber)) {
                return null;
            }
        } catch (e) {
            // console.error(e);
        }

        return error;
    };
}
export function getCommaSeparatedNames(vals: NameIdPojo[] | NameCodeIdPojo[]): string {
    if (!vals || vals.length === 0) {
        return '';
    }
    if (vals.length === 1) {
        return vals[0].name;
    }
    const allButLast = vals.slice(0, -1).map(role => role.name).join(', ');
    const last = vals[vals.length - 1].name;
    return `${allButLast} & ${last}`;
}
function formatEnumName(value: string): string {
    return value
        .toLowerCase()
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export function getFormattedEnumNames<E extends string>(vals: E[]): string  {
    if (!vals || vals.length === 0) {
        return '';
    }

    // Convert each enum value to a formatted string
    const formattedVals = vals.map(val => formatEnumName(val.toString()));

    if (formattedVals.length === 1) {
        return formattedVals[0];
    }

    const allButLast = formattedVals.slice(0, -1).join(', ');
    const last = formattedVals[formattedVals.length - 1];
    return `${allButLast} & ${last}`;
}

export function phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const phoneNumber = control.value;

        // Return null if control is empty, as the control is not required here
        if (!phoneNumber) {
            return null;
        }

        // Regular expression for validating phone numbers
        const phoneRegex = /^(\+?\d{1,3})?([ -]?)((\d{10})|(\d{3}[- ]?\d{3}[- ]?\d{4}))$/;

        // If the value does not match the regex pattern, return an error
        return phoneRegex.test(phoneNumber) ? null : {invalidPhoneNumber: true};
    };
}


export interface Config {
    errorCode?: string;
}
